import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FocusLock from 'react-focus-lock'
import classnames from 'classnames'

import { CloseButton } from '@jsluna/button'
import {
  ClickAway,
  filterProps,
  setHasOverlay as defaultSetHasOverlay,
  onKeyPress,
  getUniqueId,
} from '@jsluna/utils'

import ModalHeading from './ModalHeading'

class Modal extends Component {
  constructor(props) {
    super(props)

    this.handleEscapePress = onKeyPress([{ name: 'Escape', code: 27 }], () => {
      this.onClose()
    })
    this.headingId = props.headingId || getUniqueId('modal-')

    this.onClose = this.onClose.bind(this)
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this)
    this.handleOverlayStateChange = this.handleOverlayStateChange.bind(this)
    this.setEscapeListener = this.setEscapeListener.bind(this)
    this.removeEscapeListener = this.removeEscapeListener.bind(this)
  }

  componentDidMount() {
    const { open } = this.props

    this.handleOverlayStateChange()

    if (open) {
      this.setEscapeListener()
    }
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props

    if (prevProps.open !== open) {
      this.handleOverlayStateChange(open)

      if (open) {
        this.setEscapeListener()
      } else {
        this.removeEscapeListener()
      }
    }
  }

  componentWillUnmount() {
    const { open } = this.props

    if (open) {
      this.handleOverlayStateChange(false)
    }

    this.removeEscapeListener()
  }

  onClose() {
    const { handleClose, open } = this.props

    if (open && handleClose) {
      handleClose()
    }
  }

  setEscapeListener() {
    const { restrictClose } = this.props

    if (!restrictClose) {
      document.addEventListener('keydown', this.handleEscapePress, false)
    }
  }

  removeEscapeListener() {
    const { restrictClose } = this.props

    if (!restrictClose) {
      document.removeEventListener('keydown', this.handleEscapePress, false)
    }
  }

  handleCloseButtonClick(e) {
    const { closeElement } = this.props

    this.onClose()

    if (closeElement && closeElement.onClick) {
      closeElement.onClick(e)
    }

    e.stopPropagation()
  }

  handleOverlayStateChange(openState) {
    const { setHasOverlay, open } = this.props

    setHasOverlay(typeof openState === 'boolean' ? openState : open)
  }

  render() {
    const {
      alert,
      children,
      className,
      closeElement,
      element,
      fullScreen,
      handleClose,
      hideCloseButton,
      open,
      preventFocus,
      restrictClose,
      small,
      ...rest
    } = this.props
    const Element = element
    let describedBy

    React.Children.forEach(children, child => {
      if (child.type && child.type === ModalHeading) {
        describedBy = this.headingId
      }
    })

    return (
      <Element
        {...filterProps(rest, ['setHasOverlay', 'handleClose', 'headingId'])}
        role={alert ? 'alertdialog' : 'dialog'}
        className={classnames(className, 'ln-c-modal', {
          'is-open': open,
          'ln-c-modal--small': small,
          'ln-c-modal--full-screen': fullScreen,
        })}
        hidden={!open}
        aria-describedby={describedBy}
      >
        {open && (
          <ClickAway
            inactive={restrictClose}
            onClickAway={() => this.onClose()}
          >
            <div className="ln-c-modal__body">
              <FocusLock returnFocus autoFocus={!preventFocus}>
                <div className="ln-c-modal__scroll-area">
                  {React.Children.map(children, child =>
                    child.type && child.type === ModalHeading
                      ? React.cloneElement(child, { id: describedBy })
                      : child,
                  )}
                </div>
                {!hideCloseButton && !restrictClose && (
                  <CloseButton
                    onClick={this.handleCloseButtonClick}
                    element={closeElement}
                    innerRef={el => {
                      this.closeButtonEl = el
                    }}
                  />
                )}
              </FocusLock>
            </div>
          </ClickAway>
        )}
      </Element>
    )
  }
}

Modal.propTypes = {
  /** Sets the role to `alertdialog` for additional screen reader context - use when user action is required */
  alert: PropTypes.bool,
  /** Modal contents. Include `ModalHeading` to apply `aria-describedby` to container */
  children: PropTypes.node.isRequired,
  /** Set a custom class name to the `.ln-c-modal` element */
  className: PropTypes.string,
  /** Used to customise the `CloseButton` element for example if it should be a react-router `Link` */
  closeElement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /** Allows the top-level element to be customized */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /** When applied the modal will take up the full screen on small screens, has no affect on larger screens */
  fullScreen: PropTypes.bool,
  /** Function to control the `open` prop from outside of the component */
  handleClose: PropTypes.func,
  /** Id that gets used on the `ModalHeading` component if preset for `aria-labelledby` functionality. Will be autogenerated if left blank so set if using SSR */
  headingId: PropTypes.string,
  /** Apply prop to not show the 'x' dismiss button in the top right. The modal will still be dismissable by the escape key or clicking away */
  hideCloseButton: PropTypes.bool,
  /** Controls whether the modal is visible or not */
  open: PropTypes.bool,
  /** By default focus will sit on the first focusable element in the modal, this can be toggled off but isn't recommended as it's preferable for SSR and keyboard a11y */
  preventFocus: PropTypes.bool,
  /** Prevents the modal from being closed by either the escape key or clicking on the overlay. Also provides the effects of `hideCloseButton` */
  restrictClose: PropTypes.bool,
  /** Prop to override the setting of the `has-overlay` class on the `body` element which prevents scolling of content behind the modal */
  setHasOverlay: PropTypes.func,
  /** When set the modal has a reduced max-width on large screen devices */
  small: PropTypes.bool,
}

Modal.defaultProps = {
  alert: false,
  className: undefined,
  closeElement: undefined,
  element: 'div',
  fullScreen: false,
  handleClose: undefined,
  headingId: undefined,
  hideCloseButton: false,
  open: false,
  preventFocus: false,
  restrictClose: false,
  setHasOverlay: defaultSetHasOverlay,
  small: false,
}

Modal.displayName = 'Modal'

export default Modal
